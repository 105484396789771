<template>
  <div>
    <PageTitle>
      <PlayerCell :image="team_image" :name="player_name" />
    </PageTitle>

    <div class="container-fluid d-flex align-items-stretch h-100 flex-fill">
      <aside class="d-flex flex-column">
        <TeamHeader :image="team_image" :player_name="player_name" />
        <MapNavigation @select_map="select_map($event)" />
      </aside>

      <main v-if="loading_info" class="flex-fill d-flex justify-content-center mt-4 mb-4">Loading</main>

      <main v-else class="flex-fill mt-4 mb-4">
        <div v-if="server_error" class="text-danger">
          {{ server_error }}
        </div>

        <div class="team-headline d-flex justify-content-between align-items-end mb-5">
          <div>
            <h1>
              {{ player_name }}
              <router-link class="small" :to="`/team/${team_id}/stats/${$route.params.map_id}`">
                {{ team_name }}
              </router-link>
            </h1>
          </div>

          <b-nav v-if="selected_map_id" pills align="center">
            <b-button
              variant="outline-primary"
              :active="$route.name === 'PlayerStats'"
              :to="`/player/${$route.params.player_id}/stats/${selected_map_id}`"
            >
              Stats
            </b-button>
            <b-button
              variant="outline-primary"
              :active="$route.name === 'PlayerMatches'"
              :to="`/player/${$route.params.player_id}/matches/${selected_map_id}/1/10`"
            >
              Matches
            </b-button>
          </b-nav>
        </div>

        <router-view name="player" />
      </main>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import PageTitle from '@/components/generic/PageTitle.vue'
import PlayerCell from '@/components/Table/cells/PlayerCell.vue'
import MapNavigation from '@/components/UI/MapNavigation.vue'
import TeamHeader from '@/components/UI/TeamHeader.vue'
import mixpanel from '@/mixpanel.js'

export default {
  components: {
    PlayerCell,
    PageTitle,
    TeamHeader,
    MapNavigation,
  },

  mounted() {
    this.$store.dispatch('player/get_player_info', this.$route.params.player_id)
    this.$store.dispatch('team/update_selected_map_id', this.$route.params.map_id || this.default_map_id())

    if (!this.$route.params.map_id) {
      this.$router.replace({
        name: 'PlayerStats',
        params: {
          team_id: this.$route.params.player_id,
          map_id: this.default_map_id(),
        },
      })
    }
  },

  beforeRouteUpdate(to, from, next) {
    if (!to.params.map_id) {
      this.$router.replace({
        name: from.name,
        params: {
          player_id: to.params.player_id,
          map_id: this.default_map_id(),
        },
      })
    }

    if (to.params.player_id !== from.params.player_id) {
      this.$store.dispatch('player/get_player_info', to.params.player_id)
    }

    const [map_name] = this.maps.filter(m => m.id === to.params.map_id).map(m => m.name)

    mixpanel.track_player_sub_page(this.player_name, this.team_name, to.name, map_name)

    next()
  },

  computed: {
    ...mapGetters({
      maps: 'static/maps',
      loading_info: 'player/loading_info',
      server_error: 'player/server_error',
      player_name: 'player/name',
      team_id: 'player/team_id',
      team_name: 'player/team_name',
      team_image: 'player/team_image',
      selected_map_id: 'team/selected_map_id', // TODO make universal prop?
    }),
  },

  methods: {
    default_map_id() {
      const [ascent_id] = this.maps.map(m => m.id)
      return ascent_id
    },

    select_map(map_id) {
      if (map_id === this.$route.params.map_id) {
        return
      }

      const name = this.$route.name
      const params = {
        ...this.$route.params,
        map_id,
      }

      this.$store.dispatch('team/update_selected_map_id', map_id)

      this.$router.push({
        name,
        params,
      })

      const [map_name] = this.maps.filter(m => m.id === map_id).map(m => m.name)

      mixpanel.track_player_map(this.player_name, this.team_name, map_name)
    },
  },
}
</script>

<style lang="scss" scoped>
aside {
  margin: 1rem;
  margin-left: 0;
  width: 16vw;
}

.team-headline {
  border-bottom: 2px solid $primary;

  .nav {
    margin-bottom: -1px;
  }
}
</style>
